import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';


class News extends React.Component {
    getPageTitle = (lan) => {
        if (lan === 'en'){
            return (
                <h1>Events</h1>
            )
        }
        return(
            <h1>活動消息</h1>
        )
    }

    render(){
        const lan = localStorage.getItem('language') || 'en' ;

        return(
            <div className="page-wrap">
                <div className="events-wrap">
                    <Container>
                        {this.getPageTitle(lan)}

                        {/* The 1st Event */}
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="events-title">
                                    <h3>亞美成人日間護理協會法拉盛成立</h3>
                                    <p className="events-data">11-13-2020</p>
                                </div>
                            </Col>

                            <Col lg={12}>
                                <img src="/images/event-1.jpg" alt="logo-nameandaddress" 
                                    className="events-img"
                                />
                            </Col>

                            <Col lg={12}>
                                <img src="/images/event-2.jpg" alt="logo-nameandaddress" 
                                    className="events-img"
                                />
                            </Col>

                            <p>
                                亞美成人日間護理協會(Asian American Adult Daycare Association)
                                於2020年11月12日在法拉盛成立，志於整頓行業亂象，團結業者並提供專業指導，促進該行業健康發展。
                            </p>
                            <p>
                                亞美成人日間護理協會會長由張寶利擔任，高級行政顧問為杜彼得和於金山；在護理行業有近20年運營經驗的
                                張寶利表示，單單在法拉盛，目前有超過1萬名耆老需要照顧服務，成人日間護理髮展至今，已成為華人社區服務
                                年長者主要項目之一，華裔耆老在中心不僅可得到照顧，吃到營養配餐，還能學習新技能、保持社交等。
                            </p>
                            <p>
                                「但有些運營者把此當生意做，甚至為了爭取更多耆老會員，用返現金等涉嫌違法的手段」，
                                張寶利說，「若惡性競爭越來越嚴重，不僅對合法運營者不公平，更會對此行業產生破壞性影響。」
                            </p>
                            <p>
                                他表示，亞美成人日間護理協會是由理事會管理的會員制協會，目前已有20多家紐約華人社區的
                                日間護理中心經營者加入，短期內還會增加；協會向會員提供信息、教育、專業指導和監督管理，
                                成為政府和業者之間的橋樑，「我們希望所有會員齊心合力，按照政府的要求和法律運營，確保
                                耆老切實享受到政府為他們提供的福利，為此行業樹立積極、健康的形象。」
                            </p>
                            <p>
                                杜彼得表示，成人日間護理中心是社區為老人提供服務的最基層團體，州、市政府需要這些中心；
                                近幾十年，紐約隨著華人移民的增多，越來越多華人進入這一行業；此協會的成立能夠把業者團結起來，
                                確保規範經營，短期來看是為疫情后的重開做足準備，長遠是對這一行業能夠持續、健康的發展注入希望。
                            </p>
                        </Row>

                    </Container>
                </div>
            </div>
        )
    }
}

export default News;