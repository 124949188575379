import React from 'react';


class Footer extends React.Component {
    render(){
        return(
            <div className="footer">
                <p className="text-xs-center">
                    &copy; Copyright {new Date().getFullYear()}, Asian American Adult Daycare Association  
                </p>

                <p>Phone: 646-481-1033</p>
                <p>Address: 3 Bowery Ground FL, New York, NY 10002</p>
            </div>
        )
    }
}

export default Footer;