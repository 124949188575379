import React from 'react';
import {Jumbotron, Container, Row, Col} from 'react-bootstrap';

class App extends React.Component {
    
    getWeCare = (lan) => {
        if (lan === 'en') {
            return (
                <div>
                    <p>We Care</p>
                    <p>About You</p>
                </div>
            )
        }

        return (
            <div>
                <p>我們在乎你</p>
            </div>
        )
    }

    getIntro = (lan) => {
        if (lan === 'en') {
            return (
                <div className="intro">
                    <h3>Who we are?</h3>
                    <p>
                        <strong>Asian American Adult Daycare Association</strong> (<strong>AAADA</strong>) is a professional, 
                        pro-active advocate for adult daycare services in Asian American 
                        community of New York. 
                    </p>
                    <p>
                        AAADA is the non-profit organization that 
                        officially represents New York Asian American Adult Daycare Services 
                        providers. 
                    </p>
                    <p>
                        Governed by a Board of Directors, AAADA is a membership-based 
                        association supported by dues, grants and educational activities. 
                        Members are provided with information, education, assistance and 
                        opportunities to encourage best practices, high quality, efficiency, 
                        and success.
                    </p>
                </div>
            )            
        }

        return (
            <div className="intro">
                <h3>我們是誰？</h3>                
                <p>
                    亞美成人日間護理協會 (<strong>AAADA</strong>) 是紐約亞裔社區中專業，
                    積極為老人提供日間護理服務的倡導者。  
                </p>
                <p><strong>AAADA</strong>是非營利組織，正式代表紐約亞裔成人日間護理中心的業主們。</p>
                <p>
                    <strong>AAADA</strong>由理事會管理，是一個會員制協會，由會費，贈款和教育活動支持。
                    向會員提供信息，教育，專業協助，創造出高品質，高效率的服務和成功。
                </p>
            </div>
        )
    }

    getQuickLink = (lan) => {
        if (lan === 'en') {
            return (
                <div className="quicklink-wrap">
                    <h5>Quick Links</h5>
                    <div className="border"></div>
                    <div className="quicklink">
                        <a href="/membership">Join Membership</a>
                    </div>
                    <div className="quicklink">
                        <a href="/events">Events</a>
                    </div>
                </div>
            )
        }

        return (
            <div className="quicklink-wrap">
                <h5>了解更多</h5>
                <div className="border"></div>
                <div className="quicklink">
                    <a href="/membership">成為會員</a>
                </div>
                <div className="quicklink">
                    <a href="/events">活動消息</a>
                </div>
            </div>
        )
    }
    
    render(){
        const lan = localStorage.getItem('language') || 'en' ;

        return(
            <div className="page-wrap">
                <Jumbotron className="homepage-jumbotron">
                    <Container>
                        <Row >
                            <Col>
                                <div className="jumbotron-content">
                                    {this.getWeCare(lan)}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>

                <Container>
                    <Row>
                        <Col sm={12} md={{size:5, offset: 1}} >
                            {this.getIntro(lan)}
                        </Col>

                        <Col sm={12} md={4}>
                            {this.getQuickLink(lan)}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default App;