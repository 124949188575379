import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';


class Membership extends React.Component {
    getIntro = (lan) => {
        if (lan === 'en'){
            return(
                <Col lg={12} md={12} sm={10}>
                    <div className="membership-intro">
                        <h4 className="membership-title">
                            Become a AAADA Member
                        </h4>

                        <p>
                            JOIN TODAY! All <strong>AAADA</strong> members benefit 
                            from our ability to work together as one cohesive Association
                            to ensure that Adult Day Services remain an option of
                            choice for our seniors and people with disabilities who need
                            day health care services. 
                        </p>

                        <p>
                            <strong>AAADA</strong> is a member of the  
                            <a href="/" className="NADSA"> National Adult Day Services Association</a> (NADSA) 
                            and provides members with both state and national updates and information. 
                            AAADA is a professional, proactive advocate for adult day services in New 
                            York. Your membership gives you the opportunity to stay one step 
                            ahead of the changes in our fluid industry.
                        </p>


                        {/* Hightline */}
                        <h4 className="hightline">
                            <strong>AAADA</strong> has 2 types of membership: 
                            <br/>
                            <span>Associate Members</span> & <span>Provider Members</span>
                        </h4>


                        {/* membership-detail */}
                        <h4 className="membership-intro-title">
                            Provider Membership
                        </h4>

                        <p>
                            It is for adult day care centers only. Members must fill out the Membership Form,
                            detailing the requested information for each of your facilities.
                        </p>

                        <h4 className="membership-intro-title">
                            Associate Membership
                        </h4>

                        <p>
                            It is for vendors and consultants who want to support the adult day care industry.  
                            Membership benefits include:
                        </p>

                        <div className="membership-intro-little">
                            <h5 className="little-title">
                                Membership Support
                            </h5>

                            <p>
                                Publicity for your organization through our website and opportunities to advertise your 
                                services to our members in monthly e-mails and at our Annual Conference.
                            </p>

                            <h5 className="little-title">
                                Membership Access
                            </h5>

                            <p>
                                Ability to have access to our most current membership lists to contact our members.
                            </p>
                        </div>


                        {/* Membership Fee */}
                        <div className="membership-fee">
                            <a href="/application">
                                <h4 className="membership-fee-title">
                                    Join Now
                                </h4>
                            </a>

                            {/* 表格 */}
                            <div className="notice">
                                <p className="notice-message">
                                    If you download the form, please send to our mail box:
                                </p>
                                
                                <a href="mailto:admin@aaadanyc.com" className="notice-message-a">admin@aaadanyc.com</a>

                                <div className="notice-border"></div>

                                <a 
                                    className="notice-link"
                                    href="/docs/AAADAMembershipform.pdf"
                                    download="AAADAMembershipform.pdf"
                                >
                                    Download PDF Registration Form
                                </a>
                                <br/>
                            </div>



                            <p>
                                <span>Provider Membership</span> 
                                <br/>
                                $800 for the first center and $500 for 
                                each additional center or location under common ownership.
                            </p>

                            <p>
                                <span>Associate Membership</span> 
                                <br/>
                                $2000 per Organization. 
                            </p>
                        </div>


                        {/* Benefits to Being a Member */}
                        <div className="membership-benefit">
                            <h4 className="membership-title">
                                Benefits to Being a Member
                            </h4>
                            
                            <h4 className="membership-intro-title">
                                Legislative representation
                            </h4>

                            <p>
                                <strong>AAADA</strong> is proactive on public policy issues.  
                                <strong>AAADA</strong> works with legislators to advocate on behalf 
                                of the providers, and clients we serve, and the programs 
                                we operate.
                            </p>

                            <h4 className="membership-intro-title">
                                Valuable networking
                            </h4>

                            <p>
                                Opportunities to network and problem solve with other coalition members, 
                                MLTCs at conferences, training sessions and general membership meetings. 
                                Opportunities to affiliate with Adult Day Service associations 
                                in New York State and nationwide. 
                            </p>

                            <h4 className="membership-intro-title">
                                Staying informed
                            </h4>

                            <p>
                                Members are kept informed on legislative and state budget activity 
                                relating to adult daycare services. We provide accurate, reliable 
                                and timely updates on regulations and issues that affect ADH health service.
                            </p>

                            <h4 className="membership-intro-title">
                                Regulation oversight
                            </h4>

                            <p>
                                <strong>AAADA</strong> fosters a working relationship with Department 
                                of Health and Aging representatives and MLTCs provider relation 
                                representatives on a regular basis to discuss issues affecting the Adult 
                                Daycare industry in Asian American community. 
                            </p>

                            <h4 className="membership-intro-title">
                                Participation in informational quarterly meetings
                            </h4>

                            <p>
                                These meetings offer membership the opportunity to network together, 
                                hear timely speakers and presentations, and conduct any necessary business.
                            </p>

                            <div>
                                <p>
                                    Reduced rates at the NYADSA and NADSA conferences
                                </p>

                                <p>
                                    Opportunities to market your daycare center and programs
                                </p>

                                <p className="last-p">
                                    Opportunities to serve on committees promoting quality and 
                                    best practices in the Asian American Adult Daycare services.
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        }
        return(
            <Col lg={12} md={12} sm={10}>
                <div className="membership-intro">
                    <h4 className="membership-title">
                        成為會員
                    </h4>

                    <p>
                        JOIN TODAY! All <strong>AAADA</strong> members benefit 
                        from our ability to work together as one cohesive Association
                        to ensure that Adult Day Services remain an option of
                        choice for our seniors and people with disabilities who need
                        day health care services. 
                    </p>

                    <p>
                        <strong>AAADA</strong> is a member of the  
                        <a href="/" className="NADSA"> National Adult Day Services Association</a> (NADSA) 
                        and provides members with both state and national updates and information. 
                        AAADA is a professional, proactive advocate for adult day services in New 
                        York. Your membership gives you the opportunity to stay one step 
                        ahead of the changes in our fluid industry.
                    </p>


                    {/* Hightline */}
                    <h4 className="hightline">
                        <strong>AAADA</strong> has 2 types of membership: 
                        <br/>
                        <span>Associate Members</span> & <span>Provider Members</span>
                    </h4>


                    {/* membership-detail */}
                    <h4 className="membership-intro-title">
                        Provider Membership
                    </h4>

                    <p>
                        It is for adult day care centers only. Members must fill out the Membership Form,
                        detailing the requested information for each of your facilities.
                    </p>

                    <h4 className="membership-intro-title">
                        Associate Membership
                    </h4>

                    <p>
                        It is for vendors and consultants who want to support the adult day care industry.  
                        Membership benefits include:
                    </p>

                    <div className="membership-intro-little">
                        <h5 className="little-title">
                            Membership Support
                        </h5>

                        <p>
                            Publicity for your organization through our website and opportunities to advertise your 
                            services to our members in monthly e-mails and at our Annual Conference.
                        </p>

                        <h5 className="little-title">
                            Membership Access
                        </h5>

                        <p>
                            Ability to have access to our most current membership lists to contact our members.
                        </p>
                    </div>


                    {/* Membership Fee */}
                    <div className="membership-fee">
                        <a className="notice-link" href="/application">
                            <h4 className="membership-fee-title">
                                現在加入
                            </h4>
                        </a>

                        {/* 表格 */}
                        <div className="notice">
                            <p className="notice-message">
                                如果下載會員申請表格，
                                <br/>
                                請填寫後發到我們的電郵：
                            </p>
                            
                            <a href="mailto:admin@aaadanyc.com" className="notice-message-a">admin@aaanyc.com</a>

                            <div className="notice-border"></div>

                            <a 
                                className="notice-link"
                                href="/docs/AAADAMembershipform.pdf"
                                download="AAADAMembershipform.pdf"
                            >
                                下載會員申請表格
                            </a>
                            <br/>
                            
                        </div>



                        <p>
                            <span>Provider Membership</span> 
                            <br/>
                            $800 for the first center and $500 for 
                            each additional center or location under common ownership.
                        </p>

                        <p>
                            <span>Associate Membership</span> 
                            <br/>
                            $2000 per Organization. 
                        </p>
                    </div>


                    {/* Benefits to Being a Member */}
                    <div className="membership-benefit">
                        <h4 className="membership-title">
                            會員福利
                        </h4>
                        
                        <h4 className="membership-intro-title">
                            Legislative representation
                        </h4>

                        <p>
                            <strong>AAADA</strong> is proactive on public policy issues.  
                            <strong>AAADA</strong> works with legislators to advocate on behalf 
                            of the providers, and clients we serve, and the programs 
                            we operate.
                        </p>

                        <h4 className="membership-intro-title">
                            Valuable networking
                        </h4>

                        <p>
                            Opportunities to network and problem solve with other coalition members, 
                            MLTCs at conferences, training sessions and general membership meetings. 
                            Opportunities to affiliate with Adult Day Service associations 
                            in New York State and nationwide. 
                        </p>

                        <h4 className="membership-intro-title">
                            Staying informed
                        </h4>

                        <p>
                            Members are kept informed on legislative and state budget activity 
                            relating to adult daycare services. We provide accurate, reliable 
                            and timely updates on regulations and issues that affect ADH health service.
                        </p>

                        <h4 className="membership-intro-title">
                            Regulation oversight
                        </h4>

                        <p>
                            <strong>AAADA</strong> fosters a working relationship with Department 
                            of Health and Aging representatives and MLTCs provider relation 
                            representatives on a regular basis to discuss issues affecting the Adult 
                            Daycare industry in Asian American community. 
                        </p>

                        <h4 className="membership-intro-title">
                            Participation in informational quarterly meetings
                        </h4>

                        <p>
                            These meetings offer membership the opportunity to network together, 
                            hear timely speakers and presentations, and conduct any necessary business.
                        </p>

                        <div>
                            <p>
                                Reduced rates at the NYADSA and NADSA conferences
                            </p>

                            <p>
                                Opportunities to market your daycare center and programs
                            </p>

                            <p className="last-p">
                                Opportunities to serve on committees promoting quality and 
                                best practices in the Asian American Adult Daycare services.
                            </p>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }

    
    render(){
        const lan = localStorage.getItem('language') || 'en' ;

        return(
            <div className="page-wrap">
                <Container>
                    <Row>
                        {/* <Col lg={4} md={10} sm={10}>
                            {this.getNotice(lan)}
                        </Col> */}

                        {this.getIntro(lan)}
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Membership;