import React from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';

const Menu = [
    { en: 'Home', ch: '首頁' },
    { en: 'About Us', ch: '關於我們' },
    { en: 'Events', ch: '活動消息' },
    { en: 'Membership', ch: '會員' },
    { en: 'Contact Us', ch: '聯繫我們' }
]

class Header extends React.Component {

    setLanguage = (val) => {
        localStorage.setItem("language", val)
        window.location.reload()
    }

    getTitle = (lan) => {
        if (lan === 'en'){
            return(
                "語言"
            )
        }
        return(
            "Language"
        )
    }

    render(){
        const lan = localStorage.getItem('language') || 'en';

        return(
            <Navbar expand="lg" className="navbar-wrap">
                <Navbar.Brand href="/" className="brand-width">
                    <img src="/images/logo-fullname.png" alt="logo-short" 
                        className="logo"
                    />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="items">
                        <Nav.Link href="/" className="item">{ Menu[0][lan] }</Nav.Link>
                        <Nav.Link href="/aboutus" className="item">{ Menu[1][lan] }</Nav.Link>
                        <Nav.Link href="/events" className="item">{ Menu[2][lan] }</Nav.Link>
                        <Nav.Link href="/membership" className="item">{ Menu[3][lan] }</Nav.Link>
                        <Nav.Link href="/contactus" className="item">{ Menu[4][lan] }</Nav.Link>

                        <NavDropdown className="item" title={this.getTitle(lan)} id="basic-nav-dropdown" >
                            <NavDropdown.Item href="#action/3.1" onClick={() => this.setLanguage('ch')}>中文</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" onClick={() => this.setLanguage('en')}>English</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Header;