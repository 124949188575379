import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';


class AboutUs extends React.Component {
    getPageTitle = (lan) => {
        if (lan === 'en'){
            return(
                <h1 className="aboutus-page-title">About AAADA</h1>
            )
        }
        return(
            <h1 className="aboutus-page-title">關於 AAADA</h1>
        )
    }

    getFirstTag = (lan) => {
        if (lan === 'en'){
            return(
                <Card.Body className="aboutus-article">
                    <Card.Title className="aboutus-article-title">Mission</Card.Title>
                    
                    <Card.Text className="aboutus-article-cotent">
                        AAADA’s mission is to promote and support the growth 
                        and development of high quality Adult Daycare Services 
                        for Asian American community in New York. AAADA enhance 
                        the quality of professionalism through advocacy, education, 
                        networking and promotion of operational excellence. 
                    </Card.Text>
                </Card.Body>
            )
        }
        return(
            <Card.Body className="aboutus-article">
                <Card.Title className="aboutus-article-title">使命</Card.Title>
                
                <Card.Text className="aboutus-article-cotent">
                    AAADA的使命是促進和支持紐約亞裔社區發展高質量的成人日間護理服務。 
                    AAADA通過宣傳，教育，聯誼，交流，促進行業素質的提高。
                </Card.Text>
            </Card.Body>
        )
    }

    getSecondTag = (lan) => {
        if (lan === 'en'){
            return(
                <Card.Body className="aboutus-article">
                    <Card.Title className="aboutus-article-title">Vision</Card.Title>
                    
                    <Card.Text className="aboutus-article-cotent">
                        AAADA is the eyes, ears and voice for all Asian American Adult 
                        Daycare Services in New York on the fast moving and ever-changing 
                        issues that face our industry!
                    </Card.Text>
                </Card.Body>
            )
        }
        return(
            <Card.Body className="aboutus-article">
                <Card.Title className="aboutus-article-title">願景</Card.Title>
                
                <Card.Text className="aboutus-article-cotent">       
                    AAADA成為紐約所有亞裔成人日間護理中心的眼睛，耳朵和聲音，
                    以解決我們行業面臨的日益變化的問題和挑戰！
                </Card.Text>
            </Card.Body>
        )
    }

    getThirdTag = (lan) => {
        if (lan === 'en'){
            return(
                <Card.Body className="aboutus-article">
                    <Card.Title className="aboutus-article-title">Core Value</Card.Title>
                    
                    <Card.Text className="aboutus-article-cotent">
                        <ul>
                            <li>
                                Make a difference through leadership and pioneering actions. 
                                Inspire, provide leadership and facilitate the success of our members.
                            </li>

                            <li>
                                Respect for individuality, independence, culture and traditions at all 
                                stages of life. Provide positive experiences by serving individuals, 
                                families and Asian American community with compassion.
                            </li>

                            <li>
                                Compliance with Laws: Each member shall comply with all laws, statutes, 
                                regulations and other requirements set forth by the federal, NY State, 
                                and local government. 
                            </li>

                            <li>
                                Not using the Association for personal advantage.
                            </li>
                        </ul>
                                                                
                    </Card.Text>
                </Card.Body>
            )
        }
        return(
            <Card.Body className="aboutus-article">
                <Card.Title className="aboutus-article-title">核心價值</Card.Title>
                
                <Card.Text className="aboutus-article-cotent">
                    <ul>
                        <li>
                            通過協會的領導力和開拓力，協助每一位會員的成功。
                        </li>

                        <li>
                            尊重個體文化，傳統和獨立性，通過個性化服務為亞裔社區和家庭提供專業貼心的日間護理。
                        </li>

                        <li>
                            遵守法律，每個成員均應遵守聯邦，紐約州和地方政府規定的所有法律，法規，規章和其他要求。                                            
                        </li>
                        
                        <li>
                            不得將協會用於個人利益。
                        </li>
                    </ul>                                 
                </Card.Text>
            </Card.Body>
        )
    }

    render(){
        const lan = localStorage.getItem('language') || 'en' ;

        return(
            <div className="page-wrap">

                <Container>
                    <Row>
                        <Col lg={5} md={10} sm={4} >
                            {this.getPageTitle(lan)}
                        </Col>
                    </Row>

                    <Card className="card">
                        <Row>
                            <Col lg={4} md={10} sm={4} >
                                <Card.Img src="images/mission.jpg" className="aboutus-img"/>
                            </Col>

                            <Col lg={7} md={10} sm={4}>
                                {this.getFirstTag(lan)}
                            </Col>
                        </Row>            
                    </Card>
                </Container>

                
                <Container className="contactus-mid-container">
                    <Card className="card">
                        <Row>
                            <Col lg={4} md={10} sm={4} >
                                <Card.Img src="images/vision-new.jpg" className="aboutus-img"/>
                            </Col>

                            <Col lg={7} md={10} sm={4}>
                                {this.getSecondTag(lan)}
                            </Col>
                        </Row>            
                    </Card>
                </Container>
                
                <Container>
                    <Card className="card">
                        <Row>
                            <Col lg={4} md={10} sm={4} >
                                <Card.Img src="images/core-value-2.jpg" className="aboutus-img"/>
                            </Col>

                            <Col lg={7} md={10} sm={4}>
                                {this.getThirdTag(lan)}
                            </Col>
                        </Row>            
                    </Card>
                </Container>
            </div>
        )
    }
}

export default AboutUs;