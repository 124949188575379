// 這是路由組件，需要被導入index.js文件內，被全局調用

import React from 'react';
// BrowserRouter代表針對瀏覽器的根容器，Switch代表切換不同的路由，Route用來規劃路由路徑
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import App from './Components/App';
import AboutUs from './Pages/AboutUs';
import Events from './Pages/Events';
import Membership from './Pages/Membership';
import ContactUs from './Pages/ContactUs';
import ApplicationForm from './Pages/ApplicationForm';


const Router = () => (
    <BrowserRouter>
        <Header/>

        <Switch>
            <Route path="/" exact component={App} />
            <Route path="/aboutus" component={AboutUs} />
            <Route path="/events" component={Events} />
            <Route path="/membership" component={Membership} />
            <Route path="/contactus" component={ContactUs} />
            <Route path="/application" component={ApplicationForm} />
        </Switch>

        <Footer/>
    </BrowserRouter>
);

export default Router;