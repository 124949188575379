import React from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';

class ApplicationForm extends React.Component{
    render(){
        const lan = localStorage.getItem('language') || 'en' ;

        return(
            <div className="page-wrap">
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={10}>
                            <div className="application-ttile">
                                <h1>Application for Membership 2020-2021</h1>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={10}>
                            <div className="intro-requirements">
                                <p>
                                    <span>MEMBERSHIP REQUIREMENTS</span>: 
                                    <br/>
                                    Please see <strong>AAADA </strong> Membership/Dues Policy attached
                                    and sent to all members. This application is to be completed for 
                                    <strong> EACH</strong> program/center that is a part of or 
                                    affiliated with your organization. <span>OR that has ANY common owners, 
                                    management, corporate officers/directors, Board, or staff etc.  </span>
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={10}>
                            <div className="intro-requirements">
                                <p className="refer">
                                    The Applicant, by its  signature below, agrees to hold AAADA, 
                                    its officers, directors, employees and agents harmless in 
                                    regard  to any and all actions taken, including membership 
                                    termination, arising from or related to this application.  
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className="application-border"></div>

                {/* Form One: Join Today */}
                <Container>
                    <Row>
                        <Col lg={3} md={12} sm={12}>
                            <div className="application-small-title">
                                <h2>Join Today</h2>
                            </div>
                        </Col>
                    </Row>
                    
                    <Form className="form-wrap">
                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Applicant / Organization Name:
                            </Form.Label>

                            <Col sm={7}>
                                <Form.Control type="text"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Contact Person:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="text" placeholder="First Name & Last Name"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Title:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="text" placeholder="Manager / Coordinator"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formGridAddress">
                            <Form.Label column sm={4} className="to-left">
                                Address:
                            </Form.Label>

                            <Col sm={7}>
                                <Form.Control placeholder="1234 Main St, Manhattan, NY, 11111" />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Phone Number:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="phone" placeholder="xxx-xxx-xxxx"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Email:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="email" placeholder="xxx@gamil.com"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="exampleForm.SelectCustom">
                            <Form.Label column sm={4} className="to-left">
                                Total number(s) of Adult Day Care (ADC) Centers:
                            </Form.Label>
                            
                            <Col sm={2}>
                                <Form.Control as="select" custom>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Your Dues:
                            </Form.Label>

                            <Col column sm={2}>
                                <Form.Control type="text" readOnly placeholder="$800" />
                            </Col>

                            <Col column sm={5} className="attention">
                                <p>
                                    Membership fee is $800 for the First Center 
                                    <br/>
                                    and $500 for the Additional Center
                                </p>
                            </Col>
                        </Form.Group>

                        <div className="payment-methods">
                            <p>Please make checks payable to: <strong>ASIAN AMERICAN ADULT DAYCARE ASSOCIATION</strong> 
                                <br/>
                                Mail to: <span>AAADA Accounting, 122 Baxter Street, Ste #705, New York, NY 10013.</span>
                                <br/>
                                Or Quickpay Zelle to Zelle ID: <span>aaadanyc@gmail.com</span>
                            </p>
                        </div> 
                    </Form>
                </Container>
                

                {/* Form Two: ADC Info */}
                <Container >
                    <Row>
                        <Col lg={8} md={12} sm={12}>
                            <div className="application-small-title-two ">
                                <h2>Adult Day Care (ADC) Information</h2>
                            </div>
                        </Col>
                    </Row>

                    <Form className="form-wrap">
                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                From:
                            </Form.Label>

                            <Col column sm={2}>
                                <Form.Control type="text" readOnly placeholder="1" />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                The ADC Program's Full Name:
                            </Form.Label>

                            <Col sm={7}>
                                <Form.Control type="text" placeholder="xxx daily care center"/>
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row} controlId="exampleForm.SelectCustom">
                            <Form.Label column sm={4} className="to-left">
                                Is it for profit?
                            </Form.Label>
                            
                            <Col sm={3}>
                                <Form.Control as="select" custom>
                                    <option>For Profit</option>
                                    <option>Non-Profit</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Contact Person:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="text" placeholder="First Name & Last Name"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Title:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="text" placeholder="Manager / Coordinator"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formGridAddress">
                            <Form.Label column sm={4} className="to-left">
                                Address:
                            </Form.Label>

                            <Col sm={7}>
                                <Form.Control placeholder="1234 Main St, Manhattan, NY, 11111" />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Phone Number:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="text" placeholder="xxx-xxx-xxxx"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Fax:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="text" placeholder="xxx-xxx-xxxx"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Email:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="email" placeholder="xxx@gamil.com"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Websit:
                            </Form.Label>

                            <Col sm={5}>
                                <Form.Control type="text" placeholder="xxxx.com"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Capacity:
                            </Form.Label>

                            <Col sm={2}>
                                <Form.Control type="text" placeholder="150"/>
                            </Col>
                        </Form.Group>

                        {/* <div className="adc-notice">
                            <p>
                                Important Questions: Please fill out below as it is used for advocacy purposes.
                            </p>
                        </div> */}

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Average Daily Census:
                            </Form.Label>

                            <Col sm={2}>
                                <Form.Control type="text" placeholder="60"/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontal">
                            <Form.Label column sm={4} className="to-left">
                                Total Number of People You Have Enrolled Currently:
                            </Form.Label>

                            <Col sm={2}>
                                <Form.Control type="text" placeholder="100"/>
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
                
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={10}>
                            <div className="law">
                                <p className="top">
                                    On behalf of the applicant(s) listed herein (“Applicant”), 
                                    I hereby certify that the contents of this  application are 
                                    accurate and complete. The Applicant agrees to advise AAADA 
                                    of any significant changes in operations, management, ownership, 
                                    capacity or other similar material changes to the membership 
                                    information that may affect its membership or the information 
                                    provided herein. 
                                </p>

                                <p className="mid">
                                    The Applicant agrees to abide by AAADA’s Bylaws, 
                                    membership rules, requirements and policies, including but not limited 
                                    to the Membership and Payment of Dues Policy, and decisions of the 
                                    Board regarding membership/dues. The Applicant acknowledges that 
                                    membership may be terminated at any time if the Board reasonably 
                                    determines in its sole discretion that this application contains 
                                    false or misleading statements / information or for violation of 
                                    AAADA’s membership requirements. 
                                </p>

                                <p className="bottom">
                                    The Applicant, by its  signature below, agrees to hold AAADA, 
                                    its officers, directors, employees and agents harmless in regard 
                                    to any and all actions taken, including membership termination, 
                                    arising from or related to this application.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="sing-date">
                    <Row>
                        <Col lg={6} className="to-left">
                            <div>
                                <p>Input your name here as sign</p>
                            </div>

                            <Form.Control type="text"/>
                        </Col>

                        <Col lg={{size:2, offset:1}} className="to-left date">
                            <div>
                                <p>Date</p>
                            </div>

                            <Form.Control type="text"/>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Button type="submit" className="application-btn">Submit</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ApplicationForm;