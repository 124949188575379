import React from 'react';
import {Jumbotron, Container, Row, Col, Form, Button, Image} from 'react-bootstrap';


class ContactUs extends React.Component {

    getJumboContent = (lan) => {
        if (lan === 'en'){
            return (
                <div className="contactus-jumbo-content">
                    <h2>
                        Contact
                    </h2>

                    <h2 className="second">
                        Us
                    </h2>

                    <div className="contactus-border"></div>
                
                    <p>We care about you</p>   
                </div>   
            )
        }

        return (
            <div className="contactus-jumbo-content">
                <h2>
                    聯絡
                </h2>

                <h2 className="second">
                    我們
                </h2>

                <div className="contactus-border"></div>
            
                <p>我們在乎你</p>   
            </div>
        )
    }

    getAddressDetail = (lan) => {
        if (lan === 'en'){
            return(
                <strong className="title">Address</strong>
            )
        }
        return(
            <strong className="title">地址</strong>
        )
    }

    getWorkingHours = (lan) => {
        if (lan === 'en'){
            return(
                <p className="working-hours">
                    {<strong className="title">Working Hours</strong>}
                    <br/>

                    <span>
                        Monday:  9am - 5pm
                        <br/>
                        Tuesday:  9am - 5pm
                        <br/>
                        Wednesday:  9am - 5pm
                        <br/>
                        Thursday:  9am - 5pm
                        <br/>
                        Friday:  9am - 5pm
                        <br/>
                        Saturday: Off
                        <br/>
                        Sunday: Off
                    </span>
                </p>
            )
        }
        return(
            <p className="working-hours">
                <strong className="title">工作時間</strong>
                <br/>

                <span>
                    星期一:  9am - 5pm
                    <br/>
                    星期二:  9am - 5pm
                    <br/>
                    星期三:  9am - 5pm
                    <br/>
                    星期四:  9am - 5pm
                    <br/>
                    星期五:  9am - 5pm
                    <br/>
                    星期六: 休息
                    <br/>
                    星期日: 休息
                </span>
            </p>
        )
    }

    getContactMethod = (lan) => {
        if (lan === 'en'){
            return(
                <div className="left">
                    <h4 className="title">Contact Methods</h4>
                    
                    <p>  
                        Phone: 646-481-1033
                        <br/>
                        Email: aaadanyc@gmail.com
                    </p>
                </div>
            )
        }
        return(
            <div className="left">
                <h4 className="title">聯絡方式</h4>
                
                <p>  
                    電話: 646-481-1033
                    <br/>
                    電郵: aaadanyc@gmail.com
                </p>
            </div>
        )
    }

    getUserContact = (lan) => {
        if (lan === 'en'){
            return(
                <div className="right">
                    <h3>Leave your Information</h3>

                    <Form className="form">
                        <Form.Group controlId="first-name">
                            <Form.Label>Your First Name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" />
                        </Form.Group>

                        <Form.Group controlId="last-name">
                            <Form.Label>Your Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Last Name" />
                        </Form.Group>

                        <Form.Group controlId="phone">
                            <Form.Label>Your Phone</Form.Label>
                            <Form.Control type="number" placeholder="phone number" />
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Form.Label>Your Email</Form.Label>
                            <Form.Control type="email" placeholder="xxx@gmail.com" />
                        </Form.Group>
                        
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Notice</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>

                        <Button className="btn" type="submit">
                            Submit
                        </Button>
                    </Form>
                </div>
            )
        }
        return(
            <div className="right">
                <h3>留下你的信息</h3>

                <Form className="form">
                    <Form.Group controlId="first-name">
                        <Form.Label>你的名字</Form.Label>
                        <Form.Control type="text" placeholder="名字" />
                    </Form.Group>

                    <Form.Group controlId="last-name">
                        <Form.Label>你的姓氏</Form.Label>
                        <Form.Control type="text" placeholder="姓" />
                    </Form.Group>

                    <Form.Group controlId="phone">
                        <Form.Label>你的電話</Form.Label>
                        <Form.Control type="number" placeholder="電話" />
                    </Form.Group>

                    <Form.Group controlId="email">
                        <Form.Label>你的電郵</Form.Label>
                        <Form.Control type="email" placeholder="xxx@gmail.com" />
                    </Form.Group>
                    
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>請寫下的你留言</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>

                    <Button className="btn" type="submit">
                        提交
                    </Button>
                </Form>
            </div>
        )
    }


    render(){
        const lan = localStorage.getItem('language') || 'en' ;

        return(
            <div className="page-wrap">
                <Jumbotron className="contactus-jumbo" fluid>
                    {this.getJumboContent(lan)}
                </Jumbotron>

                {/* 地址 工作时间 */}
                <Container >
                    <Row className="addresss-wrap">
                        <Col lg={5} md={5} sm={10} >
                            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.1627797162496!2d-73.99914618383058!3d40.71443294540251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a26455330b9%3A0x67c04f4220458e42!2s3%20Bowery%2C%20New%20York%2C%20NY%2010002!5e0!3m2!1sen!2sus!4v1616763156539!5m2!1sen!2sus" width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy"></iframe>
                        </Col>

                        <Col lg={5} md={{size: 3, offset: 1}} sm={4}
                            className="address-background"
                        >
                            <div className="address-intro">
                                <p className="addresss-detail">
                                    {this.getAddressDetail(lan)}
                                    <br/>

                                    <span>
                                        3 Bowery Ground FL, New York, NY 10002
                                    </span>
                                </p>

                                {this.getWorkingHours(lan)}
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* 客户留言 */}
                <Container className="message-wrap">
                    <Row>
                        {/* 公司聯繫方式 */}
                        <Col lg={3} md={3} sm={10} >
                            {this.getContactMethod(lan)}
                        </Col>

                        {/* 客戶留言 */}
                        <Col lg={7} md={10} sm={{size:10, offset:1}}>
                            {this.getUserContact(lan)}                                                            
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ContactUs;

